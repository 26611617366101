// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap');

// Variables
@import 'variables';

// Bootstrap
/* set the overriding variables */
$grid-breakpoints: (xxxs: 0, xxs: 320px, xs: 568px, sm: 667px, md: 768px, lg: 992px, xl: 1200px, xxl: 1440px, xxxl: 1600px) !default;
$container-max-widths: (xxxs: 100%, xxs: 95%, xs: 548px, sm: 607px, md: 748px, lg: 990px, xl: 1180px, xxl: 1360px, xxxl: 1520px) !default;
$theme-colors: ("primary": #23CE77, "secondary": #262626);
@import '~bootstrap/scss/bootstrap';

// Font Awesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/core";
@import "~@fortawesome/fontawesome-free/scss/variables";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

// OwlCarousel
@import "~owl.carousel";

// NiceSelect
//@import "~jquery-nice-select/scss/style";
@import "~jquery-nice-select/scss/nice-select";

// Mapbox
@import "~mapbox-gl";

//simpleLightbox
@import "~simple-lightbox/dist/simpleLightbox.css";

//DataTables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";

// Elements
@import "elements/elements";
@import "elements/menu";

// Pages
@import "pages/home";
@import "pages/page";

// Accessibility
@import "accessibility";
