.owl-carousel {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    .owl-item{
        opacity: .2;
        filter: grayscale(1) blur(5px);
        transition: all 200ms ease-in-out;
        &.active{
            opacity: 1;
            filter: grayscale(0) blur(0);
        }
    }

    .owl-stage-outer{
        overflow: visible;
    }

    &:active, &:focus {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    .owl-dots {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-top: 30px;
        margin-left: -30px;

        .owl-dot {
            margin: 5px 0;
            width: 15px;
            height: 15px;
            background: transparent;
            border: 2px solid darken($color-white, 5%);
            border-radius: 100%;
            transition: all 150ms ease-in-out;

            &.active {
                border-color: $color-primary;
                background: $color-primary;
            }

            &:hover {
                border-color: $color-primary;
            }

            &:active, &:focus {
                border-color: darken($color-primary, 20%);
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.owl-nav {
    display: flex;
    flex-direction: column;

    button {
        width: 50px;
        height: 50px;
        background: transparent;
        border: 2px solid lighten($color-blue-gray, 15%);
        box-sizing: border-box;
        border-radius: 5px;
        color: $color-blue-gray;

        &:hover {
            border-color: lighten($color-blue-gray, 5%);
            color: $color-blue-gray;
        }

        &:active {
            border-color: $color-primary !important;
            color: $color-primary !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}
