.page {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 30px;
    @media (max-width: $xl) {
        flex-direction: column;
        padding: 0;
    }

    .sidebar {
        width: calc(30% - 60px);
        @media (max-width: $xl) {
            width: 100%;
            margin-top: 0;
            margin-bottom: 60px;
        }

        .element{
            border-radius: 15px;
            padding-right: 60px;
            overflow: hidden;
            @media (max-width: $md){
                padding-right: 0;
            }

            a {
                h3 {
                    color: $color-black;
                }

                &:hover, &.active {
                    h3 {
                        color: $color-primary;
                    }
                }
            }
        }

        .menu {
            display: flex;
            flex-direction: column;
            width: 100%;

            li {
                display: flex;
                flex-direction: column;
                width: 100%;
                font-size: 1.2rem;
                list-style-type: none;
                border-bottom: 2px solid darken($color-white, 5%);
                padding-bottom: 10px;
                margin-bottom: 15px;

                &.active {
                    > a {
                        color: $color-primary;
                        font-weight: 600;
                        padding-left: 15px;

                        &:before {
                            left: 0;
                            color: $color-primary;
                            opacity: 1;
                        }
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    color: $color-dark;
                    position: relative;

                    .date{
                        font-size: .9rem;
                        color: darken($color-white, 40%);
                    }

                    &:before {
                        content: '\f105';
                        font-family: $fontAwesome;
                        font-weight: 900;
                        position: absolute;
                        top: 4px;
                        left: -30px;
                        transition: all 200ms ease-in-out;
                        font-size: 14px;
                        color: $color-primary;
                        opacity: 0;
                    }

                    &:hover {
                        padding-left: 15px;

                        &:before {
                            left: 0;
                            opacity: 1;
                        }
                    }
                }
            }

            ul {
                padding: 30px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: auto;
        flex: 1;
        @media (max-width: $xl) {
            width: 100%;
        }

        img {
            position: relative;
            //width: 100%;
            object-fit: contain;
            margin: 30px 0;
            border-radius: 30px;
            overflow: hidden;
            &.sorting-scene{
                margin-top: -60px;
                z-index: -1;
                margin-bottom: 60px;
            }
        }

        blockquote {
            position: relative;
            overflow: hidden;
            border-radius: 30px;
            padding: 60px;
            margin: 15px 0 30px;
            font-weight: 400;
            font-size: 1.4rem;
            color: $color-dark;

            &:after {
                content: '';
                background: lighten($color-primary, 50%);
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                padding: 30px;
                z-index: -2;
                filter: grayscale(1);
                border-radius: 30px;
            }

            &:before {
                content: '\f1b8';
                font-family: $fontAwesome;
                font-weight: 900;
                font-size: 360px;
                color: lighten($color-primary, 45%);
                position: absolute;
                bottom: -45%;
                left: -30px;
                z-index: -1;
                filter: grayscale(1);
                transition: all 200ms ease-in-out;
            }

            &:hover {
                &:before {
                    transform: scale(1.1) rotate(15deg);
                }
            }
        }

        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
            font-weight: 600;
        }

        p, ul, ol, li {
            font-size: 1.2rem;
            color: darken($color-white, 75%);
        }

        ul, ol {
            padding: 0 45px;

            &.list-unstyled {
                li {
                    &:before {
                        display: none;
                    }
                }
            }

            li {
                position: relative;
                list-style-type: none;

                &.paginate_button{
                    &:before{
                        display: none;
                    }
                }

                &:before {
                    content: '\f1b8';
                    font-family: $fontAwesome;
                    font-weight: 900;
                    color: $color-primary;
                    position: absolute;
                    top: 0;
                    left: -30px;
                }

                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ul, ol {
                padding: 15px 55px;
            }
        }

        iframe{
            border: none;
            margin-bottom: 30px;
            border-radius: 15px;
            overflow: hidden;
        }

        table{
            &.dataTable{
                td{
                    vertical-align: middle;
                    padding-bottom: .75rem;
                }
                .btn{
                    &.btn-outline-primary{
                        font-size: .9rem !important;
                    }
                }
            }
        }

        .contracts{
            .dataTables_paginate{
                .pagination{
                    padding: 0 !important;
                    .paginate_button{
                        font-size: 1rem;
                    }
                }
            }
        }

        .gallery{
            display: flex;
            flex-flow: row wrap;
            margin: 30px 0 60px;
            &:after{
                content: "";
                flex: auto;
            }
            .item{
                display: flex;
                justify-content: center;
                flex-basis: 33.3%;
                @media (max-width: $lg){
                    flex-basis: 50%;
                }
                @media (max-width: $sm){
                    flex-basis: 100%;
                }
                a{
                    position: relative;
                    width: 100%;
                    display: flex;
                    height: 240px;
                    overflow: hidden;
                    background: $color-primary;
                    border-radius: 30px;
                    margin: 15px;

                    &:after{
                        content: "\f002";
                        font-family: $fontAwesome;
                        font-weight: 900;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 2;
                        font-size: 2rem;
                        color: $color-white;
                        opacity: 0;
                        transition: all 150ms ease-in-out;
                    }

                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 101%;
                        height: 101%;
                        object-fit: cover;
                        margin: 0;
                        transition: all 150ms ease-in-out;
                        border-radius: 0;
                    }

                    &:hover{
                        &:after{
                            opacity: 1;
                        }
                        img{
                            width: 103%;
                            height: 103%;
                            opacity: .3;
                            filter: grayscale(1);
                        }
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }

        #map{
            margin-bottom: 30px;
            border-radius: 15px;
            overflow: hidden;
            width: 100%;
            height: 420px;
        }

        .links {
            .grid {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: flex-start;
                align-items: flex-start;
                @media (max-width: $xl) {
                    flex-direction: column;
                }

                .box {
                    position: relative;
                    width: 50%;
                    overflow: hidden;
                    transition: all 250ms ease-in-out;
                    box-shadow: none;

                    @media (max-width: $xl) {
                        margin-bottom: 30px;
                    }

                    display: flex;
                    flex-direction: column;
                    align-content: flex-start;
                    align-items: flex-start;
                    justify-content: space-between;
                    text-decoration: none;

                    &.content-box {
                        justify-content: flex-end;

                        .hours {
                            width: 100%;
                        }

                    }

                    .box-icon {
                        display: flex;
                        margin-bottom: 15px;
                        width: 48px;
                    }

                    .date {
                        display: flex;
                        width: 100%;
                        padding-bottom: 15px;
                        color: darken($color-white, 20%);
                        font-size: 1rem;
                    }

                    .title {
                        display: flex;
                        width: 100%;
                        padding-bottom: 15px;
                        color: $color-dark;
                        font-size: 1.4rem;
                        font-weight: 700;
                    }

                    p {
                        color: darken($color-white, 50%);
                        font-size: 1.2rem;

                        strong {
                            color: $color-dark;
                        }
                    }

                    .link {
                        display: flex;
                        justify-content: flex-start;
                        align-content: flex-end;
                        align-items: center;
                        font-size: 1.2rem;

                        i {
                            font-size: 13px;
                            margin-left: 10px;
                            transition: all 150ms ease-in-out;
                        }
                    }

                    &:hover {
                        .link {
                            i {
                                margin-left: 15px;
                            }
                        }
                    }

                    @media (max-width: $xl) {
                        width: 100%;
                    }

                    .box-background {
                        position: absolute;
                        bottom: -100px;
                        right: -200px;
                        height: 100%;
                        object-fit: contain;
                        opacity: .1;
                        filter: grayscale(1);
                        z-index: 0;
                        transition: all 150ms ease-in-out;
                        transition-delay: 200ms;
                    }

                    &:hover {

                        .box-background {
                            transform: rotate(5deg) scale(1.05);
                        }

                    }
                }
            }
        }

    }

    .form {
        width: calc(50% - 60px);
        padding: 60px;
        background: darken($color-white, 2%);
        border-radius: 15px;
        margin-left: 60px;
        @media (max-width: $xl) {
            width: 100%;
            margin-top: 0;
            margin-top: 60px;
            margin-left: 0;
        }
    }
}
