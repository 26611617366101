.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        font-size: .9rem;
        text-transform: uppercase;
        font-weight: 600;
        color: darken($color-white, 40%);
        cursor: pointer;
    }

    input, textarea {
        border: 3px solid darken($color-white, 10%);
        background: transparent;
        padding: 0 15px;
        border-radius: 3px;
        font-size: 1.2rem;

        &::placeholder {
            color: darken($color-white, 45%);
        }

        &:active, &:focus {
            border-color: $color-primary;
            outline: none;
            background: $color-white;
        }
        &.is-invalid {
            border-color: #e31818;
        }
    }

    input {
        height: 55px;
    }

    textarea {
        height: 200px;
        padding: 15px;
    }
}

button {
    font-size: 1.2rem !important;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
}
