.access{
    //access-white
    &-white-on-black{
        border-top-color: $color-white !important;
        background: $color-black !important;
        color: $color-white !important;
        outline-color: yellow !important;
        box-shadow: none !important;
        header{
            .logo{
                #brand{
                    path{
                        fill: $color-white !important;
                    }
                }
                #desc{
                    fill: $color-white !important;
                    stroke: $color-white !important;
                }
            }
            .accessibility{
                .type{
                    &.font-size{
                        ul{
                            li{
                                .font-switch{
                                    background: $color-white !important;
                                    color: $color-black !important;
                                    border-color: $color-black !important;
                                }
                            }
                        }
                    }
                    &.contrast{
                        ul{
                            li{
                                .contrast-switch{
                                    &.white{
                                        background: $color-white !important;
                                        color: $color-black !important;
                                        border-color: $color-black !important;
                                    }
                                    &.white-on-black{
                                        background: transparent !important;
                                    }
                                    &.yellow{
                                        color: $color-black !important;
                                    }
                                    &.yellow-on-black{
                                        color: yellow !important;
                                        background: transparent !important;
                                    }
                                }
                            }
                        }
                    }
                    &.movie{
                        a{
                            color: $color-white !important;
                        }
                    }
                }
            }
            .search-form{
                .search{
                    border-color: $color-white !important;
                    input{
                        color: $color-white !important;
                        &::placeholder{
                            color: $color-white !important;
                        }
                    }
                    button{
                        background: $color-black !important;
                        color: $color-white !important;
                        &:hover{
                            background: $color-black !important;
                            color: $color-white !important;
                        }
                    }
                }
            }
        }
        .navbar-classic{
            background: $color-black !important;
            border: 2px solid $color-white !important;
            box-sizing: border-box !important;
            .navbar-nav{
                .nav-item{
                    .nav-link{
                        background: transparent !important;
                        svg{
                            path{
                                fill: $color-white !important;
                            }
                        }
                    }
                }
            }
            .dropdown-menu{
                background: $color-black !important;
                .dropdown-item{
                    &:before{
                        color: $color-white !important;
                    }
                }
            }
        }
        h1, h2, h3, h4, h5, h6{
            color: $color-white !important;
        }
        p{
            color: $color-white !important;
            strong{
                color: $color-white !important;
            }
        }
        strong{
            color: $color-white !important;
        }
        ul{
            li{
                color: $color-white !important;
            }
        }
        a{
            color: $color-white !important;;
            outline-color: yellow !important;
            box-shadow: none !important;
            &:hover{
                color: $color-white !important;;
            }
        }
        .btn{
            &.btn-primary{
                background: $color-white !important;
                border-color: $color-white;
                color: $color-black !important;
            }
            &.btn-outline-primary{
                border-color: $color-white !important;
                &:hover{
                    background: transparent !important;
                }
            }
        }
        .owl-carousel{
            .owl-dots{
                .owl-dot{
                    &.active{
                        background: $color-white !important;
                        border-color: $color-white !important;
                    }
                }
            }
        }
        .box{
            background: $color-black !important;
            border: 2px solid $color-white !important;
            box-sizing: border-box !important;
        }
        .homepage{
            &.start{
             .scene{
                 .image{
                     img{
                         opacity: 0;
                     }
                 }
             }
            }
            &.recycle{
                .waste-type{
                    .related{
                        .icons{
                            display: none;
                        }
                    }
                    mark{
                        color: $color-white !important;
                    }
                    .recycle-carousel{
                        .owl-stage-outer{
                            height: 100% !important;
                            .owl-stage{
                                height: 100% !important;
                                .owl-item{
                                    height: 100% !important;
                                    .box{
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                        .box{
                            .garbage{
                                display: none;
                            }
                        }
                    }
                }
            }
            &.news{
                .grid{
                    .box{
                        .date{
                            color: $color-white !important;
                        }
                    }
                }
            }
            &.waste-point{
                .scene{
                    .image{
                        img{
                            opacity: 0;
                        }
                    }
                }
            }
            &.links{
                .box{
                    .box-icon{
                        display: none;
                    }
                    .box-background{
                        display: none;
                    }
                }
            }
        }
        .page{
            .sidebar{
                .menu{
                    li{
                        &.active{
                            > a{
                                &:before{
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }
            }
            .content{
                blockquote{
                    color: $color-white !important;
                    border: 2px solid $color-white !important;
                    &:before,
                    &:after{
                        display: none;
                    }
                }
                ul, ol{
                    li{
                        color: $color-white !important;
                        &:before{
                            color: $color-white !important;
                        }
                    }
                }
                .content-box{
                    border: none !important;
                }
                p{
                    color: $color-white !important;
                    span{
                        color: $color-white !important;
                    }
                }
                .gallery{
                    .item{
                        a{
                            background: $color-white !important;
                            &:after{
                                opacity: 0 !important;
                            }
                            &:hover{
                                img{
                                    opacity: 1 !important;
                                    filter: none !important;
                                }
                            }
                        }
                    }
                }
            }
            .form{
                background: $color-black;
            }
        }
        input, textarea{
            border-color: $color-white !important;
            &::placeholder{
                color: $color-white !important;
            }
            &:active, &:focus{
                background: transparent !important;
            }
        }
        input, label, textarea{
            color: $color-white !important;
        }
        footer{
            .links{
                background: $color-black !important;
                border: 2px solid $color-white !important;
                a{
                    color: $color-white;
                    outline-color: yellow !important;
                    box-shadow: none !important;
                }
            }
            .logo{
                img{
                    filter: grayscale(1) invert(1) !important;
                }
            }
            .cta{
                background: $color-white !important;
                color: $color-black !important;
            }
        }
    }

    //access-yellow
    &-yellow-on-black{
        border-top-color: yellow !important;
        background: $color-black !important;
        color: yellow !important;
        outline-color: yellow !important;
        box-shadow: none !important;
        header{
            .logo{
                #brand{
                    path{
                        fill: yellow !important;
                    }
                }
                #desc{
                    fill: yellow !important;
                    stroke: yellow !important;
                }
            }
            .accessibility{
                .type{
                    &.font-size{
                        ul{
                            li{
                                .font-switch{
                                    background: yellow !important;
                                    color: $color-black !important;
                                    border-color: $color-black !important;
                                }
                            }
                        }
                    }
                    &.contrast{
                        ul{
                            li{
                                .contrast-switch{
                                    &.white{
                                        background: $color-white !important;
                                        color: $color-black !important;
                                        border-color: $color-black !important;
                                    }
                                    &.white-on-black{
                                        background: transparent !important;
                                        color: $color-white !important;
                                    }
                                    &.yellow{
                                        color: $color-black !important;
                                    }
                                    &.yellow-on-black{
                                        color: yellow !important;
                                        background: transparent !important;
                                    }
                                }
                            }
                        }
                    }
                    &.movie{
                        a{
                            color: yellow !important;
                        }
                    }
                }
            }
            .search-form{
                .search{
                    border-color: yellow !important;
                    input{
                        color: yellow !important;
                        &::placeholder{
                            color: yellow !important;
                        }
                    }
                    button{
                        background: $color-black !important;
                        color: yellow !important;
                        &:hover{
                            background: $color-black !important;
                            color: yellow !important;
                        }
                    }
                }
            }
        }
        .navbar-classic{
            background: $color-black !important;
            border: 2px solid yellow !important;
            box-sizing: border-box !important;
            .navbar-nav{
                .nav-item{
                    .nav-link{
                        background: transparent !important;
                        &:before{
                            background: yellow !important;
                        }
                        svg{
                            path{
                                fill: yellow !important;
                            }
                        }
                    }
                }
            }
            .dropdown-menu{
                background: $color-black !important;
                .dropdown-item{
                    &:before{
                        color: yellow !important;
                    }
                }
            }
        }
        .breadcrumbs{
            border-bottom-color: yellow !important;
        }
        h1, h2, h3, h4, h5, h6{
            color: yellow !important;
        }
        p{
            color: yellow !important;
            strong{
                color: yellow !important;
            }
        }
        strong{
            color: yellow !important;
        }
        ul{
            li{
                color: yellow !important;
            }
        }
        a{
            color: yellow !important;;
            outline-color: yellow !important;
            box-shadow: none !important;
            &:hover{
                color: yellow !important;;
            }
        }
        .btn{
            &.btn-white{
                background: yellow !important;
                color: $color-black !important;
            }
            &.btn-primary{
                background: yellow !important;
                border-color: yellow;
                color: $color-black !important;
            }
            &.btn-outline-primary{
                border-color: yellow !important;
                &:hover{
                    background: transparent !important;
                }
            }
            &.btn-outline-light{
                border-color: yellow !important;
            }
        }
        .owl-carousel{
            .owl-dots{
                .owl-dot{
                    border-color: yellow !important;
                    &.active{
                        background: yellow !important;
                        border-color: yellow !important;
                    }
                }
            }
        }
        .box{
            background: $color-black !important;
            border: 2px solid yellow !important;
            box-sizing: border-box !important;
        }
        .homepage{
            &.start{
                .scene{
                    .image{
                        img{
                            opacity: 0;
                        }
                    }
                }
            }
            &.recycle{
                .waste-type{
                    .related{
                        .icons{
                            display: none;
                        }
                    }
                    mark{
                        color: yellow !important;
                    }
                    .recycle-carousel{
                        .owl-stage-outer{
                            height: 100% !important;
                            .owl-stage{
                                height: 100% !important;
                                .owl-item{
                                    height: 100% !important;
                                    .box{
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                        .box{
                            .garbage{
                                display: none;
                            }
                        }
                    }
                }
            }
            &.news{
                .grid{
                    .box{
                        .date{
                            color: yellow !important;
                        }
                    }
                }
            }
            &.waste-point{
                .scene{
                    .image{
                        img{
                            opacity: 0;
                        }
                    }
                }
            }
            &.links{
                .box{
                    .box-icon{
                        display: none;
                    }
                    .box-background{
                        display: none;
                    }
                }
            }
        }
        .page{
            .sidebar{
                .menu{
                    li{
                        &.active{
                            > a{
                                &:before{
                                    color: yellow;
                                }
                            }
                        }
                    }
                }
            }
            .content{
                blockquote{
                    color: yellow !important;
                    border: 2px solid yellow !important;
                    &:before,
                    &:after{
                        display: none;
                    }
                }
                ul, ol{
                    li{
                        color: yellow !important;
                        &:before{
                            color: yellow !important;
                        }
                    }
                }
                .content-box{
                    border: none !important;
                }
                p{
                    color: yellow !important;
                    span{
                        color: yellow !important;
                    }
                }
                .gallery{
                    .item{
                        a{
                            background: yellow !important;
                            &:after{
                                opacity: 0 !important;
                            }
                            &:hover{
                                img{
                                    opacity: 1 !important;
                                    filter: none !important;
                                }
                            }
                        }
                    }
                }
            }
            .form{
                background: $color-black;
            }
        }
        input, textarea{
            border-color: yellow !important;
            &::placeholder{
                color: yellow !important;
            }
            &:active, &:focus{
                background: transparent !important;
            }
        }
        input, label, textarea{
            color: yellow !important;
        }
        footer{
            .links{
                background: $color-black !important;
                border: 2px solid yellow !important;
                a{
                    color: yellow;
                    outline-color: yellow !important;
                    box-shadow: none !important;
                }
            }
            .logo{
                img{
                    filter: grayscale(1) invert(1) !important;
                }
            }
            .cta{
                background: yellow !important;
                color: $color-black !important;
            }
        }
    }


    ////access-black_on_yellow
    &-black-on-yellow{
        border-top-color: $color-black !important;
        background: yellow !important;
        color: $color-black !important;
        outline-color: $color-black !important;
        box-shadow: none !important;
        header{
            .logo{
                #brand{
                    path{
                        fill: black !important;
                    }
                }
                #desc{
                    fill: $color-black !important;
                    stroke: $color-black !important;
                }
            }
            .accessibility{
                .type{
                    &.font-size{
                        ul{
                            li{
                                .font-switch{
                                    background: yellow !important;
                                    color: $color-black !important;
                                    border-color: $color-black !important;
                                }
                            }
                        }
                    }
                    &.contrast{
                        ul{
                            li{
                                .contrast-switch{
                                    &.white{
                                        background: $color-white !important;
                                        color: $color-black !important;
                                        border-color: $color-black !important;
                                    }
                                    &.white-on-black{
                                        background: $color-black !important;
                                        color: $color-white !important;
                                    }
                                    &.yellow{
                                        color: $color-black !important;
                                        background: transparent !important;
                                    }
                                    &.yellow-on-black{
                                        color: yellow !important;
                                        background: $color-black !important;
                                    }
                                }
                            }
                        }
                    }
                    &.movie{
                        a{
                            color: $color-black !important;
                        }
                    }
                }
            }
            .search-form{
                .search{
                    border-color: $color-black !important;
                    input{
                        color: $color-black !important;
                        &::placeholder{
                            color: $color-black !important;
                        }
                    }
                    button{
                        background: $color-black !important;
                        color: yellow !important;
                        &:hover{
                            background: $color-black !important;
                            color: black !important;
                        }
                    }
                }
            }
        }
        .navbar-classic{
            background: $color-black !important;
            border: 2px solid yellow !important;
            box-sizing: border-box !important;
            .navbar-nav{
                .nav-item{
                    .nav-link{
                        background: transparent !important;
                        color: yellow !important;
                        a{
                            color: yellow !important;
                        }
                        &:before{
                            background: yellow !important;
                        }
                        svg{
                            path{
                                fill: yellow !important;
                            }
                        }
                    }
                }
            }
            .dropdown-menu{
                background: $color-black !important;
                .dropdown-item{
                    color: yellow !important;
                    &:before{
                        color: yellow !important;
                    }
                }
            }
        }
        .breadcrumbs{
            border-bottom-color: $color-black !important;
        }
        h1, h2, h3, h4, h5, h6{
            color: $color-black !important;
        }
        p{
            color: $color-black !important;
            strong{
                color: black !important;
            }
        }
        strong{
            color: $color-black !important;
        }
        ul{
            li{
                color: $color-black !important;
            }
        }
        a{
            color: $color-black !important;;
            outline-color: $color-black !important;
            box-shadow: none !important;
            &:hover{
                color: $color-black !important;;
            }
        }
        .btn{
            &.btn-white{
                background: $color-black !important;
                color: yellow !important;
            }
            &.btn-primary{
                background:  $color-black !important;
                border-color:  $color-black;
                color: yellow !important;
            }
            &.btn-outline-primary{
                border-color: $color-black !important;
                color:  $color-black !important;
                &:hover{
                    background: transparent !important;
                }
            }
            &.btn-outline-light{
                border-color: $color-black !important;
            }
        }
        .owl-carousel{
            .owl-dots{
                .owl-dot{
                    border-color: $color-black !important;
                    &.active{
                        background: $color-black !important;
                        border-color: $color-black !important;
                    }
                }
            }
        }
        .box{
            background: yellow !important;
            border: 2px solid $color-black !important;
            box-sizing: border-box !important;
        }
        .homepage{
            &.start{
                .scene{
                    .image{
                        img{
                            opacity: 0;
                        }
                    }
                }
            }
            &.recycle{
                .waste-type{
                    .related{
                        .icons{
                            display: none;
                        }
                    }
                    mark{
                        color: $color-black !important;
                    }
                    .recycle-carousel{
                        .owl-stage-outer{
                            height: 100% !important;
                            .owl-stage{
                                height: 100% !important;
                                .owl-item{
                                    height: 100% !important;
                                    .box{
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                        .box{
                            .garbage{
                                display: none;
                            }
                        }
                    }
                }
            }
            &.news{
                .grid{
                    .box{
                        .date{
                            color: $color-black !important;
                        }
                    }
                }
            }
            &.waste-point{
                .scene{
                    .image{
                        img{
                            opacity: 0;
                        }
                    }
                }
            }
            &.links{
                .box{
                    .box-icon{
                        display: none;
                    }
                    .box-background{
                        display: none;
                    }
                }
            }
        }
        .page{
            .sidebar{
                .menu{
                    li{
                        border-bottom-color: $color-black;
                        &.active{
                            > a{
                                &:before{
                                    color: yellow;
                                }
                            }
                        }
                    }
                }
            }
            .content{
                blockquote{
                    color: $color-black !important;
                    border: 2px solid $color-black !important;
                    &:before,
                    &:after{
                        display: none;
                    }
                }
                ul, ol{
                    li{
                        color: $color-black !important;
                        &:before{
                            color: $color-black !important;
                        }
                    }
                }
                .content-box{
                    border: none !important;
                }
                p{
                    color: $color-black !important;
                    span{
                        color: $color-black !important;
                    }
                }
                .gallery{
                    .item{
                        a{
                            background: yellow !important;
                            &:after{
                                opacity: 0 !important;
                            }
                            &:hover{
                                img{
                                    opacity: 1 !important;
                                    filter: none !important;
                                }
                            }
                        }
                    }
                }
            }
            .form{
                background: yellow;
            }
        }
        input, textarea{
            border-color: $color-black !important;
            &::placeholder{
                color: $color-black !important;
            }
            &:active, &:focus{
                background: transparent !important;
            }
        }
        input, label, textarea{
            color: $color-black !important;
        }
        footer{
            color: yellow !important;
            h1, h2, h3, h4, h5, h6{
                color: yellow !important;
            }
            p{
                color: yellow !important;
            }
            ol, ul{
                li{
                    color: yellow !important;
                }
            }
            a{
                color: yellow !important;
                outline-color: $color-black !important;
                box-shadow: none !important;
            }
            .links{
                background: $color-black !important;
                border: 2px solid $color-black !important;
                a{
                    color: yellow;
                    outline-color: $color-black !important;
                    box-shadow: none !important;
                }
                .menu{
                    .submenu{
                        .submenu-links{
                            color: yellow !important;
                            .link{
                                color: yellow !important;
                                a{
                                    color: yellow !important;
                                }
                            }
                        }
                    }
                }
            }
            .disclaimer{
                p{
                    color: $color-black !important;
                    a{
                        color: $color-black !important;
                    }
                }
            }
            .logo{
                img{
                    filter: hue-rotate(-90deg) saturate(100%) !important;
                }
            }
            .cta{
                background: yellow !important;
                color: $color-black !important;
            }
        }
    }

}

.font-size_1 {
    font-size: 16px;
}

.font-size_2{
    font-size: 18px;
}

.font-size_3{
    font-size: 20px;
}
