.homepage {

    &.start,
    &.waste-point {
        margin: 50px 0;

        @media (max-width: $lg) {
            margin: 25px 0;
        }

        .scene {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;

            @media (max-width: $lg) {
                flex-direction: column;
            }

            .image {
                width: 50%;
                position: relative;
                z-index: 1;
                @media (max-width: $lg) {
                    width: 100%;
                }

                img {
                    width: 150%;
                    left: -35%;
                    position: relative;
                    @media (max-width: 1600px) {
                        width: 120%;
                        left: -10%;
                    }
                    @media (max-width: $lg) {
                        width: 100%;
                        left: 0;
                    }
                }
            }

            .text {
                width: 40%;
                margin-left: 10%;
                position: relative;
                z-index: 2;
                @media (max-width: $lg) {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 30px;
                }

                .title {
                    font-size: 2.4rem;
                    font-weight: 700;
                }

                p {
                    font-size: 1.25rem;
                    color: darken($color-white, 50%);
                }
            }
        }

    }

    &.start {
        margin: 0;
        padding: 80px 0;

        .scene {
            .image {
                width: 50%;
                position: relative;
                z-index: -1;
                @media (max-width: $lg) {
                    width: 100%;
                }

                img {
                    position: absolute;
                    width: 175%;
                    right: -55%;
                    top: 50%;
                    transform: translateY(-50%);
                    @media (max-width: 1600px) {
                        width: 150%;
                        left: -25%;
                    }
                }
            }

            .text {
                width: 50%;
                margin-left: 0;
                @media (max-width: $lg) {
                    width: 100%;
                    margin-left: 0;
                    margin-top: -70px;
                    padding-bottom: 30vw;
                }

            }
        }
    }

    &.waste-point {
        margin: 0 0 30px;
    }

    &.news{
        .box{
            padding: 0;
            img{
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
            }
            .content{
                padding: 30px;
            }
        }
    }

    &.news,
    &.links {
        .grid {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: calc(100% + 30px);
            margin-left: -15px;
            flex-wrap: wrap;
            @media (max-width: $xl) {
                flex-direction: column;
            }

            &:after{
                content: "";
                flex: 1 auto;
            }

            .box {
                position: relative;
                width: calc(33.3% - 30px);
                overflow: hidden;
                transition: all 250ms ease-in-out;
                margin: 0 15px 30px;

                @media (max-width: $xl) {
                    margin-bottom: 30px;
                }

                display: flex;
                flex-direction: column;
                align-content: flex-start;
                align-items: flex-start;
                //justify-content: space-between;
                text-decoration: none;

                .content{
                    height: 100%;
                    display:flex;
                    flex-direction: column;
                    //justify-content: space-between;
                    flex: 1;
                    padding-bottom: 80px;
                }

                &.content-box {
                    justify-content: flex-end;

                    .hours {
                        width: 100%;
                    }

                }

                .box-icon {
                    display: flex;
                    margin-bottom: 15px;
                    width: 48px;
                }

                .date {
                    display: flex;
                    width: 100%;
                    padding-bottom: 15px;
                    color: darken($color-white, 20%);
                    font-size: 1rem;
                }

                .title {
                    display: flex;
                    width: 100%;
                    padding-bottom: 15px;
                    color: $color-dark;
                    font-size: 1.4rem;
                    font-weight: 700;
                }

                p {
                    color: darken($color-white, 50%);
                    font-size: 1rem;

                    strong {
                        color: $color-dark;
                    }
                }

                .link {
                    display: flex;
                    justify-content: flex-start;
                    align-content: flex-end;
                    align-items: center;
                    font-size: 1.2rem;
                    position: absolute;
                    bottom: 30px;
                    left: 30px;

                    i {
                        font-size: 13px;
                        margin-left: 10px;
                        transition: all 150ms ease-in-out;
                    }
                }

                a{
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }

                &:hover {
                    .link {
                        i {
                            margin-left: 15px;
                        }
                    }
                }

                @media (max-width: $xl) {
                    width: 100%;
                }

                .box-background {
                    position: absolute;
                    bottom: -50px;
                    right: -50px;
                    height: 100%;
                    object-fit: contain;
                    opacity: .1;
                    filter: grayscale(1);
                    z-index: 0;
                    transition: all 150ms ease-in-out;
                    transition-delay: 200ms;
                }

                &:hover {
                    transform: translateY(-10px);

                    .box-background {
                        transform: rotate(5deg) scale(1.05);
                    }

                }
            }
        }
    }

    &.recycle {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-top: 100px;
        margin-bottom: 30px;
        @media (max-width: 1480px) {
            margin-top: -20px;
        }
        @media (max-width: $xl) {
            margin-top: -60px;
        }
        @media (max-width: $lg) {
            margin-top: 200px;
        }
        @media (max-width: $md) {
            margin-top: 14vw;
            margin-bottom: 100px;
        }

        .owl-dots {
            position: absolute;
            top: -30px;
            left: calc(-33.3% - 5px);

            @media (max-width: $xl) {
                left: calc(-50% - 15px);
            }

            @media (max-width: $md) {
                left: auto;
                right: 0;
                top: inherit;
                bottom: -70px;
                flex-direction: row;
            }

            button {
                @media (max-width: $md) {
                    margin: 5px;
                }
            }

        }

        .waste-type {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media (max-width: $sm) {
                flex-direction: column;
            }

            .related {
                width: calc(25% - 30px);
                position: relative;
                z-index: 5;

                @media (max-width: $xl) {
                    width: calc(33.3% - 30px);
                }

                @media (max-width: $md) {
                    width: calc(50% - 30px);
                }

                @media (max-width: $sm) {
                    width: 100%;
                    margin-bottom: 30px;
                }

                .box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    height: 100%;
                    background: rgb(135, 55, 255);
                    min-height: 366px;
                    position: relative;

                    a {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        align-items: center;

                        .icons {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 110%;
                            transition: all 200ms ease-in-out;
                            opacity: .75;
                        }

                        .title {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            justify-content: center;
                            align-content: center;
                            align-items: center;
                            font-size: 1.4rem;
                            color: lighten(rgb(135, 55, 255), 20%);
                            line-height: 1;
                            text-align: center;
                            padding: 5px 0;
                            @media (max-width: $md) {
                                font-size: 1.2rem;
                            }

                            mark {
                                color: $color-white;
                                background: none;
                                font-size: 2.4rem;
                                font-weight: 700;
                                margin: 0;
                                transition: all 150ms ease-in-out;
                                padding: 5px 0;
                                @media (max-width: $md) {
                                    font-size: 2rem;
                                }
                            }
                        }

                        .btn {
                            &.btn-white {
                                position: absolute;
                                bottom: 30px;
                                right: 30px;
                                width: 40px;
                                height: 40px;
                                display: flex;
                                justify-content: center;
                                align-content: center;
                                align-items: center;
                                background: $color-white;
                                border-radius: 999px;
                                padding: 0;
                                color: rgb(135, 55, 255);
                            }
                        }

                        &:hover {
                            .icons {
                                transform: translate(-50%, -50%) scale(.9) rotate(10deg);
                            }

                            .title {
                                mark {
                                    letter-spacing: .25rem;
                                    @media (max-width: $md) {
                                        letter-spacing: 0;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .recycle-carousel {
                width: 75%;
                @media (max-width: $xl) {
                    width: 66.6%;
                }

                @media (max-width: $md) {
                    width: 50%;
                }

                @media (max-width: $sm) {
                    width: 100%;
                }

                .box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    .garbage {
                        width: 125px;
                        height: 125px;
                        object-fit: contain;
                        margin-bottom: 30px;
                        transition: all 150ms ease-in-out;
                    }

                    .title {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        justify-content: center;
                        align-content: center;
                        align-items: center;
                        font-size: 1.4rem;
                        padding-bottom: 30px;
                        @media (max-width: $md) {
                            font-size: 1.2rem;
                        }

                        mark {
                            background: none;
                            font-size: 2.4rem;
                            font-weight: 700;
                            padding: 0;
                            margin: 0;
                            transition: all 150ms ease-in-out;
                            line-height: 1;
                            @media (max-width: $md) {
                                font-size: 2rem;
                            }

                            &.green {
                                color: $color-primary;
                            }

                            &.yellow {
                                color: #F1CB4F;
                            }

                            &.blue {
                                color: #4A75CB;
                            }

                            &.black {
                                color: #262626;
                            }
                        }
                    }

                    &:hover {
                        .title {
                            mark {
                                letter-spacing: .25rem;
                                @media (max-width: $md) {
                                    letter-spacing: 0;
                                }
                            }
                        }

                        .garbage {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }

    &.shape {
        background: darken($color-white, 2.5%);
        animation: morph 8s ease-in-out infinite;
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
        height: 75vw;
        transition: all 1s ease-in-out;
        width: 75vw;
        z-index: -1;
        position: absolute;
        top: -30%;
        right: -10%;
    }

}
