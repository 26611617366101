.box {
    background: $color-white;
    border-radius: 15px;
    padding: 30px;
    overflow: hidden;
    box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.05);
}

.boxes {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0;

    .title {
        font-size: 1.6rem;
        padding-bottom: 10px;
    }

    .list {
        padding-bottom: 0;

        li {
            font-size: 1.2rem !important;
            margin-bottom: 5px;
        }
    }

    .box-primary {
        background: $color-primary;
        color: $color-white;
        padding: 30px;
        border-radius: 10px;
        width: 100%;
        position: relative;
        overflow: hidden;

        .box-icon{
            position: absolute;
            color: darken($color-primary, 3%);
            font-size: 200px;
            bottom: -45%;
            right: -40px;
            transition: all 150ms ease-in-out;
        }

        &:hover{
            .box-icon{
                transform: rotate(10deg) scale(1.25);
            }
        }

        .list {
            li {
                color: $color-white;

                &:before {
                    content: '\f00c' !important;
                    color: $color-white !important;
                }
            }
        }
    }

    .box-secondary {
        color: $color-dark;
        padding: 30px;
        border-radius: 10px;
        width: 100%;
        border: 2px solid darken($color-white, 5%);
        margin-top: 30px;
        position: relative;
        overflow: hidden;

        .box-icon{
            position: absolute;
            color: darken($color-white, 3%);
            font-size: 200px;
            bottom: -20%;
            right: -40px;
            transition: all 150ms ease-in-out;
        }

        &:hover{
            .box-icon{
                transform: rotate(10deg) scale(1.25);
            }
        }

        .list {
            li {
                color: $color-dark;

                &:before {
                    content: '\f00d' !important;
                    color: crimson !important;
                }
            }
        }
    }
}
