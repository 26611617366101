.btn {
    box-sizing: border-box;
    font-weight: 500;
    border-radius: 500px;
    padding: .45rem 1.5rem;
    text-decoration: none;
    text-align: center;
    color: $color-primary;
    @media (max-width: $sm) {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &.btn-primary {
        border: 2px solid $color-primary;
        background: $color-primary;
        color: $color-white;

        &:hover {
            border-color: darken($color-primary, 5%);
            background: darken($color-primary, 5%);

            &:active {
                border-color: darken($color-primary, 10%);
                background: darken($color-primary, 10%);
                outline: none !important;
                box-shadow: none !important;
            }
        }
    }

    &.btn-light {
        border: 2px solid darken($color-white, 10%);
        background: darken($color-white, 10%);
        color: darken($color-white, 60%);

        &:hover {
            border-color: darken($color-white, 15%);
            background: darken($color-white, 15%);

            &:active {
                border-color: darken($color-white, 20%);
                background: darken($color-white, 20%);
                outline: none !important;
                box-shadow: none !important;
            }
        }

        &.outline{
            background: transparent;
            color: darken($color-white, 30%);
            &:hover {
                border-color: darken($color-white, 15%);

                &:active {
                    color: darken($color-white, 80%);
                    background: darken($color-white, 5%);
                    border-color: darken($color-white, 20%);
                    outline: none !important;
                    box-shadow: none !important;
                }
            }
        }
    }

    &.btn-outline-light {
        border: 2px solid lighten($color-blue-gray, 15%);
        color: $color-blue-gray;

        &:hover {
            border-color: lighten($color-blue-gray, 10%);
            color: darken($color-blue-gray, 10%);
            background: transparent;
        }

        &:active {
            border-color: $color-blue-gray !important;
            color: darken($color-blue-gray, 20%) !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }

    &.btn-outline-white {
        border: 2px solid $color-white;
        color: $color-white;

        &:hover {
            border-color: $color-white;
            color: $color-black;
            background: $color-white;
        }

        &:active {
            color: $color-black;
            border-color: $color-white !important;
            background: darken($color-white, 10%) !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

.loading-button {
    position: relative;

    &:after {
        content: '\f110';
        display: flex;
        align-items: center;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        animation: spin 1s linear infinite;
        opacity: 0;
    }

    &.loading:after {
        opacity: 1;
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
