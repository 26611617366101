.navbar-classic {
    background: $color-menu;
    margin: 50px 0;
    border-radius: 999px;

    @media (max-width: $hamburger-width) {
        border-radius: 15px;
        overflow: hidden;
        padding: 30px;
    }

    .search-form {
        display: none;
        background: lighten($color-menu, 5%);
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
        height: 75px;
        width: 85%;
        overflow: hidden;
        @media (max-width: $sm) {
            width: 70%;
        }
        @media (max-width: 410px){
            display: none !important;
        }

        .search {
            width: 100%;

            input {
                height: 100%;
                padding: 0 20px;
                width: calc(100% - 78px);
                background: transparent;
                color: $color-white;
                border: none;
                transition: all 150ms ease-in-out;

                @media (max-width: 380px) {
                    padding-right: 0;
                    width: calc(100% - 65px);
                }

                &::placeholder {
                    color: lighten($color-menu, 45%);
                }
            }

            button {
                width: 72px;
                height: 72px;
                background: lighten($color-menu, 15%);
                border: none;
                color: $color-white;
                padding: 0;
                margin: 0;
                border-radius: 999px;
                @media (max-width: 380px) {
                    width: 45px;
                }
                @media (max-width: $sm) {
                    background: transparent;
                }
            }
        }

        @media (max-width: $hamburger-width) {
            display: flex;
            flex-direction: row;
        }
    }

    &.navbar {
        padding: 0;
    }

    .navbar-nav {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        margin: 0;
        @media (max-width: 380px) {
            width: 100% !important;
        }
        @media (max-width: $xxl) {
            padding-left: 30px;
            padding-right: 30px;
        }

        .nav-item {
            height: 100%;

            &.start-item {
                @media (max-width: $xxl) {
                    display: none;
                }

                .nav-link {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    background: lighten($color-menu, 10%);
                    color: $color-menu;
                    border-radius: 999px;
                    width: 77px;
                    height: 77px;
                    padding: 0;
                    margin: 0;

                    &:before {
                        display: none;
                    }

                    svg {
                        path {
                            fill: lighten($color-menu-secondary, 60%);
                            transition: all 200ms ease-in-out;
                        }
                    }

                    &:hover {
                        background: lighten($color-menu, 15%);

                        svg {
                            path {
                                fill: $color-white;
                            }
                        }
                    }
                }
            }

            &.contact-item {

                .nav-link {
                    background: lighten($color-menu, 5%);
                    padding: 25px 50px;
                    border-radius: 999px;
                    transition: all 200ms ease-in-out;
                    overflow: hidden;

                    @media (max-width: $xxl) {
                        display: none;
                    }

                    &:before {
                        display: none;
                    }

                    svg {
                        position: relative;
                        top: -2px;
                        margin-left: 15px;
                        height: 25px;
                        animation: phoneRinging 3s 5s ease-in-out infinite;
                    }

                    &:hover {
                        background: lighten($color-menu, 8%);
                    }
                }
            }

            .nav-link {
                color: $color-white;
                font-weight: 600;
                font-size: 1.15rem;
                @media (max-width: 1368px){
                    font-size: 1rem;
                }
                padding: 25px 0;
                transition: all 200ms ease-in-out;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background: $color-menu;
                    transition: all 200ms ease-in-out;
                    z-index: 55;
                }

                &:hover {
                    color: lighten($color-menu-secondary, 40%);

                    &:before {
                        background: lighten($color-menu-secondary, 40%);
                        width: 100%;
                    }

                    &.dropdown-toggle::after {
                        color: $color-menu-secondary;
                    }
                }
            }
        }
    }

    .dropdown-toggle::after {
        margin-top: 5px;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 6px;
        vertical-align: -2px;
        content: "\f107";
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: none;
        color: #bdbebf;
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        margin-right: 15px;
        font-size: 13px;
        float: right;
        top: 50%;
    }
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 40;
    display: none;
    float: left;
    min-width: 16rem;
    padding: 0;
    color: $color-white;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: darken($color-menu, 5%) url("../img/icons/icon-menu.svg") no-repeat 90% 90%;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 25px;
    clear: both;
    color: $color-white;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    position: relative;
    font-size: 1.15rem;

    &:before {
        width: 0;
        height: 0;
        font-family: "Font Awesome 5 Free";
        content: "\f105";
        color: $color-menu-secondary;
        font-weight: 900;
        font-size: 13px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -10px;
        opacity: 0;
        transition: all 200ms ease-in-out;
        transition-delay: 50ms;
    }

    &:focus, &:hover {
        color: lighten($color-menu-secondary, 50%);
        text-decoration: none;
        background-color: rgba(0, 0, 0, .2);

        &:before {
            opacity: 1;
            left: 15px;
        }
    }

    &:focus .dropdown-toggle::after, &:hover.dropdown-toggle::after {
        color: $color-menu-secondary;
    }
}

.dropdown-submenu {
    .dropdown-menu {
        border-radius: 0;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background: darken(#3a3a3a, 3%) url("../img/icons/icon-submenu.svg") no-repeat 90% 90%;
    }
}

@media (min-width: 992px) {
    .dropdown {
        .dropdown-menu {
            display: block;
            visibility: hidden;
            opacity: 0;
            -webkit-transform: translateY(20px);
            -ms-transform: translateY(20px);
            transform: translateY(20px);
            -webkit-transition: all .3s ease-in;
            -o-transition: all .3s ease-in;
            transition: all 0.3s ease-in;
        }

        &:hover > .dropdown-menu {
            visibility: visible;
            opacity: 1;
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
            opacity: 1;
            visibility: visible;
        }
    }

    .dropdown-submenu:hover .dropdown-menu {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
    }
}

.dropdown-submenu {
    position: relative;

    a::after {
        transform: rotate(-90deg);
        position: absolute;
        right: 17px;
        top: 25px;
    }

    .dropdown-menu {
        top: 0px;
        left: 100%;
        @media (max-width: $hamburger-width) {
            background-image: none;
        }

        .dropdown-item {
            border-bottom: darken($color-menu-secondary, 5%);
        }
    }
}

.caret-up {
    width: 0;
    height: 0;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-bottom: 4px solid;
    display: inline-block;
    margin-left: 2px;
    vertical-align: middle;
}

.mega-dropdown {
    position: unset;
}

.mega-dropdown-menu {
    width: 100%;
    position: absolute;
    top: 63px;
    margin: 15px 0px;

    ul {
        list-style: none;
    }
}

.nav-dropitem-arrow {
    position: relative;
    font-size: 12px;
    opacity: 0;
    padding-right: 10px;

    i {
        position: absolute;

        /* top: -1px;
        */
    }
}

.dropdown-item {
    &:focus {
        color: lighten($color-menu-secondary, 40%);
        text-decoration: none;
        background: transparent;
        padding-left: 35px;
    }

    &:hover {
        color: lighten($color-menu-secondary, 40%);
        text-decoration: none;
        background: transparent;
        padding-left: 35px;

        .nav-dropitem-arrow {
            color: lighten($color-menu-secondary, 40%);
            opacity: 1;
            margin-right: 5px;
        }
    }
}

.navbar-classic .navbar-toggler, .navbar-boxed .navbar-toggler {
    @media (max-width: $hamburger-width) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        position: relative;
    }
    @media (max-width: 410px){
        width: 100%;
    }
}

/* mobile menu css */

.navbar-classic .navbar-collapse.show {
    .dropdown .dropdown-menu {
        box-shadow: none;
    }
}

.navbar-boxed .navbar-collapse.show {
    .dropdown .dropdown-menu {
        box-shadow: none;
    }

}

.navbar-transparent .navbar-collapse.show {
    .dropdown .dropdown-menu {
        box-shadow: none;
    }

    .navbar-nav .nav-item .nav-link {
        border-bottom: 1px solid $color-menu-secondary;
    }

}

.navbar-collapse{
    @media (max-width: 410px){
        width: 100%;
    }
}

.icon-bar {
    width: 21px;
    height: 3px;
    background-color: $color-white;
    display: block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-top: 4px;
}

.navbar-toggler {
    &:focus {
        outline: 0px;
    }

    .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 190%;
        background-color: $color-white;
    }

    .middle-bar {
        opacity: 0;
    }

    .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% -80%;
        background-color: $color-white;
    }

    &.collapsed {
        .top-bar {
            transform: rotate(0);
            background-color: $color-white;
        }

        .middle-bar {
            opacity: 1;
        }

        .bottom-bar {
            transform: rotate(0);
            background-color: $color-white;
        }
    }
}
