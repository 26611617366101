@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes phoneRinging {
    0% {
        transform: rotate(0);
    }
    5% {
        transform: rotate(30deg);
    }
    10% {
        transform: rotate(-28deg);
    }
    15% {
        transform: rotate(34deg);
    }
    20% {
        transform: rotate(-32deg);
    }
    25% {
        transform: rotate(30deg);
    }
    30% {
        transform: rotate(-28deg);
    }
    35% {
        transform: rotate(26deg);
    }
    40% {
        transform: rotate(-24deg);
    }
    45% {
        transform: rotate(22deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    55% {
        transform: rotate(18deg);
    }
    60% {
        transform: rotate(-16deg);
    }
    65% {
        transform: rotate(14deg);
    }
    70% {
        transform: rotate(-12deg);
    }
    75% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(-8deg);
    }
    85% {
        transform: rotate(6deg);
    }
    90% {
        transform: rotate(-4deg);
    }
    95% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(-1deg);
    }
}


@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}
